import React from "react";
import axios from "axios";
import {API_URI} from "../../constant/Config";
import {SmileOutlined} from '@ant-design/icons';
import {Typography, Table, Form, Input, Radio, Button, Checkbox, DatePicker, Result, Alert, Col, Row} from "antd";
import moment from 'moment-timezone';
//moment.tz.setDefault("Europe/Paris");

const {Title, Text} = Typography;

class OrderTransactions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id,
            dataTrans: false,
            formDeclaration: <></>,
            formType: 'physique',
            idTransactionSelected: false,
            loadingBtnDeclaration: false,
        }
    }

    async componentDidMount() {

        await this.getTrans();
    }

    onFinish = async (values) => {
        this.setState({loadingBtnDeclaration: true});
        console.log('Success:', values);
        var token = localStorage.getItem('membre_token');
        var datePaiement = moment(values.datePaiement);

        const datePaiementTzParis = datePaiement.tz('Europe/Paris').format('YYYY-MM-DD HH:mm:ss');


        var valuePost = {
            ...values,
            idCmd: this.state.id,
            idTrans: this.state.idTransactionSelected,
            datePaiementTzParis: datePaiementTzParis
        }


        let dataCmd = await axios.post(API_URI + '/api_member/donateur-trans-add', valuePost, {
            headers: {
                'Authorization': token
            }
        })

        this.setState({
            formDeclaration: <Result
                icon={<SmileOutlined/>}
                title="Merci, votre déclaration a bien été transmise"
            />
        })

        return dataCmd.data;

    };


    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    getTrans = async () => {
        const liste = await axios.post(API_URI + '/api_member/payment-list-order', {id: this.state.id});
        await this.setState({dataTrans: liste});
    }


    loadForm = (idTransaction, formType) => {
        this.setState({
            formDeclaration: this.formDeclaration(idTransaction, formType)
        })

    }


    formDeclaration = (idTransaction, formType) => {
        if (formType == 'physique') {
            this.setState({})
            var typeForm = <div>
                <Form.Item
                    label="Nom"
                    name="nom"
                    rules={[
                        {
                            required: true,
                            message: 'Merci de remplir cette information',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Prénom"
                    name="prenom"
                    rules={[
                        {
                            required: true,
                            message: 'Merci de remplir cette information',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
            </div>;
        } else {
            var typeForm = <div>
                <Form.Item
                    label="Dénomination"
                    name="denomination"
                    rules={[
                        {
                            required: true,
                            message: 'Merci de remplir cette information',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Forme juridique"
                    name="forme_juridique"
                    rules={[
                        {
                            required: true,
                            message: 'Merci de remplir cette information',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="SIREN"
                    name="siren"
                    rules={[
                        {
                            required: true,
                            message: 'Merci de remplir cette information',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
            </div>;

        }
        this.setState({idTransactionSelected: idTransaction})
        return (
            <Form
                name="basic"
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                labelCol={{
                    span: 4,
                }}
                wrapperCol={{
                    span: 10,
                }}
                layout="horizontal"
            >
                <Row>
                    <Col span={10} offset={4}>
                        <Alert message="Si plusieurs personnes ont fait des dons pour votre projet, par exemple, via une cagnotte en ligne, veuillez nous contacter au téléphone ou par mail pour connaitre la marche à suivre pour obtenir les reçus fiscaux." type="info"/>
                    </Col>
                </Row>
                <br />
                <Form.Item label="Vous êtes" name="size">
                    <Radio.Group defaultValue="particulier">
                        <Radio.Button value="particulier" onClick={() => this.loadForm(idTransaction, 'physique')}>Un
                            particulier</Radio.Button>
                        <Radio.Button value="entreprise" onClick={() => this.loadForm(idTransaction, 'societe')}>Une
                            entreprise</Radio.Button>
                    </Radio.Group>
                </Form.Item>

                {typeForm}

                <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                        {
                            type: 'email',
                            required: true,
                            message: 'Le format du mail est incorrect ou non renseigné.',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="N°"
                    name="numero"
                    rules={[
                        {
                            required: true,
                            message: 'Merci de remplir cette information',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Nom de rue"
                    name="rue"
                    rules={[
                        {
                            required: true,
                            message: 'Merci de remplir cette information',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Code Postal"
                    name="cp"
                    rules={[
                        {
                            required: true,
                            message: 'Merci de remplir cette information',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Commune"
                    name="commune"
                    rules={[
                        {
                            required: true,
                            message: 'Merci de remplir cette information',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Date du paiement"
                    name="datePaiement"
                    rules={[
                        {
                            required: true,
                            message: 'Merci de remplir cette information',
                        },
                    ]}>
                    <DatePicker format={'DD/MM/YYYY'}/>
                </Form.Item>

                <Form.Item
                    wrapperCol={{offset: 4, span: 10}}
                    name="confirmation"
                    valuePropName="checked"
                    rules={[
                        {
                            required: true,
                            message: 'Merci de cocher cette case pour confirmer votre saisie',
                        },
                    ]}
                >
                    <Checkbox>Par la présente, je soussigné(e) (Prénom et Nom du client) déclare sur l'honneur que les
                        informations fournies dans la présente déclaration sont exactes et complètes.</Checkbox>
                </Form.Item>


                <Form.Item wrapperCol={{offset: 4, span: 10}}>
                    <Button type="primary" htmlType="submit" loading={this.state.loadingBtnDeclaration}>Valider votre
                        déclaration</Button>
                </Form.Item>
            </Form>
        );
    }

    render() {
        const columns = [
            {
                title: 'Id',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: 'Montant',
                dataIndex: 'montant',
                key: 'montant',
            },
            {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
            },
            {
                title: 'Etat',
                dataIndex: 'etat',
                key: 'etat',
            },
            {
                title: '',
                dataIndex: 'action',
                key: 'action',
            },
        ];

        if (this.state.dataTrans != false) {
            const dataTrans = this.state.dataTrans.data.data;
            var dataFinal = [];
            var thisInst = this;
            var listeDates = Object.keys(dataTrans).map(function (key) {
                var action = '';
                if (dataTrans[key]['idCat'] !== 3) {
                    //if (localStorage.getItem('membre_id') == '15' || localStorage.getItem('membre_id') == '23') {
                    if (dataTrans[key]['libEtat'] === 'Validé' && dataTrans[key]['declarationStatut'] == false) {
                        action =
                            <Button type="primary" onClick={() => thisInst.loadForm(dataTrans[key]['id'], 'physique')}>Déclaration
                                du don</Button>;
                    } else if (dataTrans[key]['libEtat'] === 'Validé' && dataTrans[key]['declarationStatut'] == true) {
                        action = 'Déclaration déjà effectée';
                    }
                //}
                }

                dataFinal.push({
                    key: key,
                    id: dataTrans[key]['id'],
                    montant: dataTrans[key]['amount'],
                    date: dataTrans[key]['date'],
                    etat: dataTrans[key]['libEtat'],
                    action: action
                })
            });
            var data = dataFinal
        } else {
            var data = []
        }

        return (
            <div>
                {this.state.formDeclaration}
                <Table columns={columns} dataSource={data}/>
            </div>
        );
    }
}

export default OrderTransactions;